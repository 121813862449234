<template>
  <div id="campaignListPage">
    <!-- Header -->
    <div class="border-b m-3 px-3 pt-1 pb-2 bg-white border rounded-md overflow-hidden">
      <div class="text-xl font-semibold leading-7 pt-2">
        {{ channel }} Campaigns
        <template v-if="!fetchingData">({{ pageOptions.total }})</template>
      </div>

      <div class="mt-1 flex" style="font-size: 12px; font-weight: 500 !important" v-if="['email', 'sms', 'whatsApp'].indexOf(channelType) > -1">
        <!-- Email Video Tutorial -->
        <template v-if="channelType == 'email'">
          <a class="text-indigo-600 hover:text-indigo-600 hover:no-underline background-transparent outline-none focus:outline-none tracking-normal" href="https://support.growlytics.in/campaigns/campaigns-overview/email/creating-email-campaign" target="_blank" @click="marketingTrackEvent('Tutorial Link Clicked', { page: 'Email Campaign' })">
            <i class="fa-brands fa-readme"></i>
            How To Run Email Campaigns
          </a>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <a class="text-indigo-600 hover:text-indigo-600 hover:no-underline background-transparent outline-none focus:outline-none tracking-normal" href="https://youtu.be/k7IKPKh42SU" target="_blank" @click="marketingTrackEvent('Tutorial Link Clicked', { page: 'Email Campaign Youtube Video', youtube: true })">
            <i class="fa-brands fa-youtube"></i>
            Watch Video
          </a>
        </template>
        <!-- SMS - Video Tutorial -->
        <template v-if="channelType == 'sms'">
          <a class="text-indigo-600 hover:text-indigo-600 hover:no-underline background-transparent outline-none focus:outline-none tracking-normal" href="https://support.growlytics.in/campaigns/campaigns-overview/sms/creating-sms-campaign" target="_blank" @click="marketingTrackEvent('Tutorial Link Clicked', { page: 'SMS Campaign' })">
            <i class="fa-brands fa-readme"></i>
            How To Run SMS Campaigns
          </a>
        </template>
        <!-- WhatsApp - Video Tutorial -->
        <template v-if="channelType == 'whatsApp'">
          <a class="text-indigo-600 hover:text-indigo-600 hover:no-underline background-transparent outline-none focus:outline-none tracking-normal" href="https://support.growlytics.in/campaigns/campaigns-overview/whatsapp/creating-whatsapp-campaign" target="_blank" @click="marketingTrackEvent('Tutorial Link Clicked', { page: 'WhatsApp Campaign' })">
            <i class="fa-brands fa-readme"></i>
            How To Run WhatsApp Campaigns
          </a>
        </template>
      </div>
    </div>

    <!-- Filters -->
    <div class="filters px-3 pb-2">
      <el-input class="searchCampaignInput" prefix-icon="el-icon-search" size="small" v-model="searchFilter" @input="onSearch" placeholder="Search Campaign"> </el-input>
      <el-select @change="resetPaginationAndSearch" class="statusFilter" size="small" v-model="statusFilter" multiple collapse-tags placeholder="Campaign Status">
        <el-option v-for="item in filterOptions.status" :key="item.value" :label="item.label" :value="item.value"> </el-option>
      </el-select>
      <el-select @change="resetPaginationAndSearch" class="campaignType" size="small" v-model="campaignType" multiple collapse-tags placeholder="Campaign Type">
        <el-option v-for="item in filterOptions.campaignType" :key="item.value" :label="item.label" :value="item.value"> </el-option>
      </el-select>

      <div style="float: right">
        <el-button type="warning" size="small" :loading="creatingCampaign" v-if="isBalanceButtonVisible()" @click="$refs.channelBalance.showPopup()"> Check Balance </el-button>
        <el-button type="primary" size="small" :loading="creatingCampaign" @click="onCreateCampaignClick"> Create {{ channel }} Campaign </el-button>
      </div>
    </div>

    <!-- Loader -->
    <div class="loadingDiv border mx-3 overflow-hidden" v-if="fetchingData && campaignList" v-loading="true" style="min-height: 300px"></div>

    <!-- Campaign List -->
    <div class="campaignList mx-3 mb-5" v-if="!fetchingData && campaignList && campaignList.length > 0">
      <!-- Header Row -->
      <el-row class="headerRow border px-2 py-3 text-black">
        <el-col :span="10" class="details">Campaign Name</el-col>
        <el-col :span="4">Start Time</el-col>
        <el-col :span="2">Delivered</el-col>
        <el-col :span="2">Opened</el-col>
        <el-col :span="2">Clicked</el-col>
        <el-col :span="2">Created By</el-col>
        <el-col :span="2">Create At</el-col>
      </el-row>

      <el-row class="campaignItem border-b border-l border-r" v-for="(campaign, index) in campaignList" :key="index">
        <el-col class="details py-2.5" :span="10">
          <div>
            <router-link class="detailsLink" :to="getCampaignDetailsPageLink(campaign)">
              <span v-if="!campaign.name">--</span>
              <span v-else>{{ campaign.name }}</span>
            </router-link>
          </div>
          <div class="metaInfo">
            <div class="statusTag scheduled" v-if="campaign.status == 'scheduled'">SCHEDULED</div>
            <div class="statusTag running" v-if="campaign.status == 'running'">RUNNING</div>
            <div class="statusTag ended" v-if="campaign.status == 'ended'">ENDED</div>
            <div class="statusTag draft" v-if="campaign.status == 'draft'">DRAFT</div>
          </div>
          <div class="metaInfo">
            <span class="statusTag campaignType">{{ campaignTypeDisplayNames[campaign.type] }}</span>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="startTime">
            <span v-if="campaign.status == 'draft' && campaign.start_type == 'now'">
              Start <br />
              Immediately
            </span>
            <span v-else v-html="campaign.start_time"> </span>
          </div>
        </el-col>

        <el-col :span="2">
          <div class="campaignStats">
            {{ getNumberWithComma(campaign.stats.delivered) }}
          </div>
        </el-col>
        <el-col :span="2">
          <div class="campaignStats">
            {{ getNumberWithComma(campaign.stats.unique_opened) }}
          </div>
        </el-col>
        <el-col :span="2">
          <div class="campaignStats">
            {{ getNumberWithComma(campaign.stats.unique_clicked) }}
          </div>
        </el-col>
        <!-- Updated Created By Column -->
        <el-col :span="2">
          <div class="startTime" v-html="campaign.created_by"></div>
        </el-col>
        <el-col :span="2">
          <div class="startTime" v-html="campaign.created_at"></div>
        </el-col>
      </el-row>

      <!-- Bottom Pagination -->
      <div class="pagination pagination-bottom">
        <div class="totalCount">Total {{ pageOptions.total }} Campaigns</div>
        <el-pagination @current-change="fetchCampaignList" :current-page.sync="pageOptions.pageNumber" :page-size="pageOptions.pageSize" layout="prev, pager, next" :total="pageOptions.total"></el-pagination>
      </div>
    </div>

    <!-- No Data -->
    <div class="noDataDiv bg-white border rounded-md mx-3" v-if="!fetchingData && campaignList && campaignList.length == 0">
      <div class="msg">No Campaigns Found</div>
      <el-button class="createCampaignButton" :loading="creatingCampaign" type="primary" @click="onCreateCampaignClick"> Create Your First Campaign </el-button>

      <div class="campaignFeature">
        Campaign allows you to increase sales and engagement with customers
        <br />
        by engaging automated,behavior-driven multi-channel campaigns.

        <el-row :gutter="30" class="featureList">
          <el-col :span="8">
            <i class="fa fa-comments"></i>
            <div class="title">Improve Customer Engagement</div>
            <div class="description">Deliver behavior-based, automated multi channel messages that keep customers engaged every step of the way.</div>
          </el-col>
          <el-col :span="8">
            <i class="fa fa-magic" aria-hidden="true"></i>
            <div class="title">Automate Precise Campaigns</div>
            <div class="description">Target the right customer segments with beautiful, precise messages for one-off and ongoing campaigns.</div>
          </el-col>
          <el-col :span="8">
            <i class="fa fa-line-chart" aria-hidden="true"></i>
            <div class="title">Understand What Drives Sales</div>
            <div class="description">Measure campaign impact beyond opens and clicks to truly understand what initiatives drive sales.</div>
          </el-col>
        </el-row>
      </div>
    </div>

    <ChannelBalance ref="channelBalance" :channelType="channelType" :channel="channel"></ChannelBalance>
  </div>
</template>
<style lang="scss" src="./campaignList.scss"></style>

<script>
import CampaignListComponent from './campaignListComponent';
export default CampaignListComponent;
</script>
